import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ProjectCard bg="#ffffff" image="/zoe-feature-portrait.jpg" imageMobile="/zoe-feature-landscape.jpg" logo="/zoe-lss-logo-round.png" title="Zoe - Living Sea Sculpture" location="Cozumel, Mexico" description="Dive to visit this underwater memorial sculpture in a restoration zone in Cozumel, Mexico. The payment goes towards the running costs of the charitable project." price="20 USD pp (not inc equipment rental)" imageAlt="Diver measuring coral size at restoration structure in Cozumel, Mexico" mdxType="ProjectCard">
  <li>Introduction to restoration techniques</li>
  <li>Dive briefing</li>
  <li>40 minute dive to the living sea sculpture</li>
  <li>Assist with work during the dive</li>
  <li>View your dive on the recorded live stream</li>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Emoji from "../components/emoji";
import { OutboundLink } from "gatsby-plugin-google-gtag";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Help build The Reef App`}</h2>
    <p>{`Do you want to join the community members working on designing and building the tools for the global reef restoration community? We are a currently a small team of volunteers and we’re looking for all sorts of skills, including: programming, content, marketing, language or education.`}</p>
    <p>{`Diving experience preferable `}<Emoji symbol="🤿" label="snorkel" mdxType="Emoji" /></p>
    <OutboundLink target="_blank" href="mailto:hello@thereefapp.com" mdxType="OutboundLink">hello@thereefapp.com</OutboundLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
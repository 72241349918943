import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Put your project on the map, bring more people and support to your coral restoration efforts`}</h2>
    <ul>
      <li parentName="ul">{`Bring more people and support to your project`}</li>
      <li parentName="ul">{`Connect with experienced scientists and citizen scientists`}</li>
      <li parentName="ul">{`Build alliances for funding and research`}</li>
      <li parentName="ul">{`Share knowledge and techniques`}</li>
      <li parentName="ul">{`Identify opportunities in region or practice`}</li>
    </ul>
    <p><strong parentName="p">{`...being part of the community gives everyone more time to spend in active restoration.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TestimonialCard = makeShortcode("TestimonialCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <TestimonialCard title="Maria" image="/maria-2.jpg" imageAlt="My first coral transplant using marine epoxy under the supervision of Zoe’s Lead Biologist" mdxType="TestimonialCard">
   "My first coral transplant using marine epoxy under the supervision of the Lead Biologist."
    </TestimonialCard>
    <TestimonialCard hiddenMobile title="Penn" image="/penn.jpg" imageAlt="Divers at a coral restoration structure in Cozumel" mdxType="TestimonialCard">
  "We are so lucky to have such a badass group of beautiful mermaids helping us care for our reformed reef project!"
    </TestimonialCard>
    <TestimonialCard hiddenMobile title="Colleen" image="/colleen-2.jpg" imageAlt="Divers at a coral restoration structure in Cozumel" mdxType="TestimonialCard">
  "Going on an underwater camera maintenance dive! So that we can keep enjoying a clear image on the livestream!"
    </TestimonialCard>
    <TestimonialCard title="Solene" image="/solene-2.jpg" imageAlt="Divers at a coral restoration structure in Cozumel" mdxType="TestimonialCard">
  "Transplantation week 🌱 is my favourite week! We collect and transplant fallen fragments and monitor their growth"
    </TestimonialCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { OutboundLink } from "gatsby-plugin-google-gtag";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Get in touch`}</h2>
    <OutboundLink target="_blank" href="mailto:hello@thereefapp.com" mdxType="OutboundLink">hello@thereefapp.com</OutboundLink>
    <p>{`@thereefapp
tag us #thereefapp.`}</p>
    <OutboundLink target="_blank" href="https://www.instagram.com/thereefapp" height="48px" style={{
      "padding": "8px"
    }} mdxType="OutboundLink">Instagram</OutboundLink>
    <OutboundLink target="_blank" href="https://www.facebook.com/thereefapp/" height="48px" style={{
      "padding": "8px"
    }} mdxType="OutboundLink">Facebook</OutboundLink>
    {
      /*OutboundLink target="_blank" href="https://www.instagram.com/thereefapp" height="48px" style="padding: 8px;">Twitter</OutboundLink*/
    }
    <OutboundLink target="_blank" href="https://www.linkedin.com/company/the-reef-app/" height="48px" style={{
      "padding": "8px"
    }} mdxType="OutboundLink">LinkedIn</OutboundLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
/** @jsx jsx */
import { jsx } from "theme-ui"
// @ts-ignore
import FeatureMDX from "../sections/feature"

const FeaturedProject = () => (
    <div
        sx={{
        }}
    >
        <FeatureMDX />
    </div>
)

export default FeaturedProject

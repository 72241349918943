import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Be part of the community, join frontline restoration efforts`}</h2>
    <ul>
      <li parentName="ul">{`Discover and support projects around the world`}</li>
      <li parentName="ul">{`Help projects grow and create income for local communities`}</li>
      <li parentName="ul">{`See the global impact of your actions`}</li>
      <li parentName="ul">{`Connect with other coral restorers`}</li>
      <li parentName="ul">{`Develop skills, learn the latest techniques & share knowledge`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      